import './bootstrap'
import 'lazysizes';
import loader from './modules/loader'
import nav from './modules/main-nav'
// // import {sideNav} from './modules/side-nav'
// // import formActions from './modules/form-actions'
// // import imageSlider from './plugins/image-slider'
import whenDomReady from 'when-dom-ready';
import './plugins/google-maps'


whenDomReady(() => {
  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker.register('/sw.js')
  // }
  console.log()
  nav()

  const lightBox = dq('.gallery')
  if (lightBox !== null) {
    loader('gallery', '.gallery')
  }

})
