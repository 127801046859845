import Vue from 'vue'

const lazyContext = require.context('../components/global/lazy', true, /.vue$/, 'lazy')
const context = require.context('../components/global/default', true, /.vue$/)

context.keys().forEach(fileName => {
  // Get component config
  const globalModule = context(fileName)
  // Get PascalCase name of component
  const name = fileName.replace(/.*(\\|\/)/, '').replace(/\.\w+$/, '')
  // Register component globally
  Vue.component(name, globalModule.default || globalModule)
})

lazyContext.keys().forEach(fileName => {
  // Get component config
  const globalModule = lazyContext(fileName)
  // Get PascalCase name of component
  const name = fileName.replace(/.*(\\|\/)/, '').replace(/\.\w+$/, '')
  // Register component globally
  Vue.component(name, () => globalModule.default || globalModule)
})

