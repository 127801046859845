global.initialize_map = function initialize_map() {
  let obj = {
    map: document.getElementById('map-canvas'),
  };
  let location = {
    lat: parseFloat(obj.map.getAttribute('data-lat')),
    lng: parseFloat(obj.map.getAttribute('data-lng')),
  }
  let options = {
    zoom: 15,
    scaleControl: true,
    center: location,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    draggable: false,
    mapTypeControl: false,
    disableDefaultUI: true,
  };
  let map = new google.maps.Map(obj.map, options);
  let marker = new google.maps.Marker({
    position: location,
    map: map,
    title: 'Wood Window Services Ltd',
  });
}
