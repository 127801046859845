import debounce from 'lodash/debounce'

export default () => {
  new Vue({
    el: '.main-nav',
    data: {
      open: false,
      width: 0,
    },
    created() {
      window.addEventListener('resize', debounce(() => {
        this.width = document.body.clientWidth
      }, 500))
    },
    computed: {
      currentClass() {
        if ( this.width <= 768 ) {
          if (this.open) {
            return 'max-h-screen'
          }
        }
        return ''
      },
    },
    methods: {
      clicked() {
        this.open = !this.open
      },
    },
  })
}
