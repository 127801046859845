import Vue from 'vue'

import './lib/register-base-components'
import './lib/register-global-components'

if ( process.env.APP_DEBUG == 'true' ) {
  Vue.config.devtools = true
}

global.Vue = Vue
global.dq = function(selector) {
  if (selector[0] === '.') {
    return document.querySelector(selector)
  }
  return document.getElementById(selector)
}
